<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
import {GetOrganizationAllAndAmount,getOrganizeList,GetEachCompanyUsingTaskNumberGroupByDayChartData} from '../../../../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      companynamelist:[],
      companypeoplelist:[],
      companyidlist:[],
      listone:[],
      serdata:[],
      xdata:[],
      companyAndPeopleCountArr:[],
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      GetOrganizationAllAndAmount().then(res=>{
        // console.log(res);
        if(res.data.code==200)
        {
          for(var a=0;a<res.data.data.length;a++)
          {
            this.companyAndPeopleCountArr.push({
              Id:res.data.data[a].deptId,
              Name:res.data.data[a].deptName,
              CascadeCode:res.data.data[a].cascadeCode,
              UserAmount:res.data.data[a].userCount,
            })
          }

          // this.companyAndPeopleCountArr=res.data.response;
          // console.log('count');
          // console.log(this.companyAndPeopleCountArr);
          // this.getdata();
          this.getdata2(res);
        }
      });

      //this.listone=[1,4,3,5,6,3,1,3];
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {

    getdata2(res)
    {
      if(res.status==200)
      {
        var responseArr=res.data.data;
        // console.log(responseArr);
        responseArr.forEach(element => {
          // console.log(element.deptName);
          // if(element.parentId!=null && (element.deptName.indexOf('冷却塔')==-1))
          if(element.parentId!=null)
          {
            var peopleCount=0;
            for(var p=0;p<this.companyAndPeopleCountArr.length;p++)
            {
              if(this.companyAndPeopleCountArr[p].Id==element.deptId)
              {
                peopleCount=this.companyAndPeopleCountArr[p].UserAmount;
                break;
              }
            }
            var companyid=element.deptId;
            var companyname=element.deptName+'('+peopleCount+')';
            this.companyidlist.push(companyid);
            this.companynamelist.push(companyname);
            this.companypeoplelist.push(peopleCount);
          }
        });
        var lookday=15;
        for(var i=0;i<=lookday;i++){
          this.xdata[i]=this.getDay(-i);
        }
        this.xdata=this.xdata.reverse();
        //console.log(this.xdata)

        GetEachCompanyUsingTaskNumberGroupByDayChartData({companylist:this.companyidlist,beforeday:lookday}).then(res=>{
          if(res.status==200)
          {
            var chartdata=res.data.response;
            //console.log(chartdata)
            for(var c=0;c<chartdata.length;c++)
            {
              //console.log(this.companypeoplelist[c])
              var peoplecount=this.companypeoplelist[c];
              if(peoplecount==0)
              {
                continue;
              }
              var arr=new Array();
              for(var n=0;n<chartdata[c].length;n++)
              {
                var temp=0;
                if(chartdata[c][n]!=0)
                {
                  if(peoplecount==0)
                  {
                    temp=0;
                  }else{
                    var number1=chartdata[c][n];
                    var number2=peoplecount;
                    if(number1>=number2)
                    {
                      temp=100;
                    }else{
                      temp =(Math.round(chartdata[c][n] / peoplecount * 10000) / 100.00);
                      //console.log('number1:'+number1+',number2:'+number2+',temp:'+temp);
                    }
                  }
                }
                arr.push(temp);
              }
              //console.log(arr)
              this.serdata.push({
                  name: this.companynamelist[c],
                  type: 'line',
                  // data: chartdata[c]
                  data: arr
              })
            }
            this.initChart()
          }
        });

      }
      // })
    },
    getdata()
    {
      getOrganizeList().then(res=>{
        //console.warn("getOrganizeList")
        //console.log(res);
        if(res.status==200)
        {
          var responseArr=res.data.response;
          responseArr.forEach(element => {
            if(element.ParentId!=null && (element.Name.indexOf('冷却塔')==-1))
            {
              var peopleCount=0;
              for(var p=0;p<this.companyAndPeopleCountArr.length;p++)
              {
                if(this.companyAndPeopleCountArr[p].Id==element.Id)
                {
                  peopleCount=this.companyAndPeopleCountArr[p].UserAmount;
                  break;
                }
              }
              //console.log(peopleCount);
              var companyid=element.Id;
              var companyname=element.Name+'('+peopleCount+')';
              this.companyidlist.push(companyid);
              this.companynamelist.push(companyname);
              this.companypeoplelist.push(peopleCount);
              //console.log(element.Name);
            }
          });

          // for(var a=0;a<this.companynamelist.length;a++)
          // {
          //   this.serdata.push({
          //       name: this.companynamelist[a],
          //       type: 'line',
          //       data: [120+a*5, 132+a*9, 101+a*12, 134+a*14, 90, 230, 210]
          //   })
          // }

          // console.log(this.serdata)
          // console.log(this.companynamelist)
          //console.log(this.companyidlist);


          var lookday=15;
          for(var i=0;i<=lookday;i++){
            this.xdata[i]=this.getDay(-i);
          }
          this.xdata=this.xdata.reverse();
          //console.log(this.xdata)

          GetEachCompanyUsingTaskNumberGroupByDayChartData({companylist:this.companyidlist,beforeday:lookday}).then(res=>{
            if(res.status==200)
            {
              var chartdata=res.data.response;
              //console.log(chartdata)
              for(var c=0;c<chartdata.length;c++)
              {
                //console.log(this.companypeoplelist[c])
                var peoplecount=this.companypeoplelist[c];

                var arr=new Array();
                for(var n=0;n<chartdata[c].length;n++)
                {
                  var temp=0;
                  if(chartdata[c][n]!=0)
                  {
                    var number1=chartdata[c][n];
                    var number2=peoplecount;
                    temp =(Math.round(chartdata[c][n] / peoplecount * 10000) / 100.00);
                    //console.log('number1:'+number1+',number2:'+number2+',temp:'+temp);
                  }
                  arr.push(temp);
                }
                //console.log(arr)
                this.serdata.push({
                    name: this.companynamelist[c],
                    type: 'line',
                    // data: chartdata[c]
                    data: arr
                })
              }
              this.initChart()
            }
          });

        }
      })
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData);
      // this.listone = res.data.response[0];
      //   this.listtwo = res.data.response[1];
      //   this.listthree = res.data.response[2];
    },
    setOptions({ expectedData, actualData } = {}) {
      this.chart.setOption({
          title: {
              //text: '集团各单位任务录入使用概览'
          },
          tooltip: {
              trigger: 'axis',
              // formatter:'{c}人'
              formatter: function(a) {
                // console.log(a);

                let list = []
                list.push('<font style="font-weight:bold">集团各单位任务录入、维护当天使用率</font><hr/>')
                let listItem = ''
                for (var i = 0; i < a.length; i++) {
                    list.push(
                        '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                            a[i].color +
                            ';margin-right: 5px;border-radius: 50%;}"></i><span style="width:240px; display:inline-block;">' +
                            a[i].seriesName +
                            '</span>：<span style="width:35px">'+a[i].value+'%</span>' 
                    )
                }
                listItem = list.join('<br>')
                return '<div class="showBox">' + listItem + '</div>'
              }
          },
          legend: {
              // data: this.companynamelist
              // data: ['行政中心']
              data:[]
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xdata
          },
          yAxis: {
              type: 'value'
          },
          series: this.serdata
          // [
          //     {
          //         name: '行政中心',
          //         type: 'line',
          //         data: [120, 132, 101, 134, 90, 230, 210]
          //     },
          //     {
          //         name: '通风一公司',
          //         type: 'line',
          //         data: [220, 182, 3500, 234, 290, 330, 310]
          //     },
          //     {
          //         name: '人防公司',
          //         type: 'line',
          //         data: [150, 232, 201, 154, 190, 330, 410]
          //     },
          //     {
          //         name: '直接访问',
          //         type: 'line',
          //         data: [320, 332, 301, 334, 390, 330, 320]
          //     },
          //     {
          //         name: '搜索引擎',
          //         type: 'line',
          //         data: [820, 932, 901, 934, 1290, 1330, 1320]
          //     }
          // ]
      })
    }
  }
}
</script>
